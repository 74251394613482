import React, { useState, useEffect, useRef } from 'react'
import { CircularProgress, Typography, Button, Fade } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from './validation'
import { forgetPassword } from 'actions/login'
import { Link } from 'gatsby'
import useFirebaseRecaptcha from 'hooks/useFirebaseRecaptcha'
import useStyles from './styles'

function Login() {
  const classes = useStyles()
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues: { email: '' } })

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { ref, verified } = useFirebaseRecaptcha()

  const onSubmitForm = async ({ email }) => {
    if (verified) {
      try {
        await forgetPassword(email)
        setSuccess("We've sent password reset instructions to the email address!")
      } catch (e) {
        setError(e.message)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Typography variant="h3" className={classes.greeting}>
        Reset Password
      </Typography>
      <Fade in={!!error}>
        <Typography color="secondary" className={classes.errorMessage}>
          {error}
        </Typography>
      </Fade>
      <Fade in={!!success}>
        <Typography color="primary" className={classes.errorMessage}>
          {success}
        </Typography>
      </Fade>
      <Fade in={!!success}>
        <Typography color="primary" className={classes.errorMessage}>
          <>
            Please click <Link to="/login">here</Link> to login.
          </>
        </Typography>
      </Fade>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <CustomInput {...field} label="Email Address" error={errors.email} style={{ marginTop: '10px' }} fullWidth />
        )}
      />
      <div ref={ref} />
      <div className={classes.formButtons}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          disabled={!verified || !isDirty || isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={30} /> : 'Reset Password'}
        </Button>
      </div>
    </form>
  )
}

export default Login
