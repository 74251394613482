import React from 'react'
import ForgetPassword from 'components/ForgetPassword'
import LoginLayout from 'components/Layout/Login'

const ForgetPage = () => (
  <LoginLayout>
    <ForgetPassword />
  </LoginLayout>
)
export default ForgetPage
